<template>
  <div class="pre-body pre-body-sm">
    <img class="pre-icon" src="/assets/images/access.png" alt="" />

    <div class="pre-subhead centered">Enter your Ticket Code</div>

    <error-alert v-if="validTicketCode === false">
      Ticket code not recognized - Please check ensure you have typed it
      correctly. If that doesn't work please <a href="#" @click="logout">logout and re-register</a> to try again.<br><br>
      Need help?
      <router-link :to="{ name: $routerItems.FAQS }"
        >Please check the FAQs</router-link
      >
    </error-alert>
    <error-alert v-if="pastTicket">
      This show has occurred in the past.
    </error-alert>
    <error-alert v-if="inUsing">
      The ticket code has already been associated with another profile. Please
      use another ticket code.
    </error-alert>
    <div class="pre-form--group">
      <div class="pre-btns centered" v-b-toggle.collapse-hint>
        <div class="pre-btn pre-btn-underline">
          <div class="pre-btn--copy">Where can I find this?</div>
        </div>
      </div>
      <b-collapse id="collapse-hint" class="mt-2">
        <div class="row">
          <div class="col-6">
            <p class="text-small">
              Your ticket code is sent with your tickets. Search your email
              account for "tickets@universe.com" and look for a code with the
              format CXXXXXXXX.
            </p>
          </div>
          <div class="col-6 text-center">
            <lazy-img
              class="pre-image qr-code"
              src="/assets/images/qr-example.png"
              alt=""
            />
          </div>
        </div>
      </b-collapse>
    </div>
    <b-form-group label="Ticket Code" class="ticket-code mb-4">
      <pnw-input
        v-model="form.ticketCode"
        type="text"
        placeholder="9 digit code"
        @keyup="checkTicketCode"
      ></pnw-input>
      <b-spinner
        variant="primary"
        label="Spinning"
        v-if="checking"
        class="spinner"
      ></b-spinner>
    </b-form-group>
    <div class="d-flex justify-content-between">
      <div>
        <label class="mb-0">Show Date</label>
        <div class="ticket-info" v-if="validTicketCode">{{ showDate }}</div>
        <img
          class="pre-form--value"
          src="/assets/images/code-1.png"
          alt=""
          v-if="!validTicketCode"
        />
      </div>
      <div>
        <label class="mb-0">Time</label>
        <div class="ticket-info" v-if="validTicketCode">{{ showTime }}</div>
        <img
          class="pre-form--value"
          src="/assets/images/code-2.png"
          alt=""
          v-if="!validTicketCode"
        />
      </div>
      <div>
        <label class="mb-0">Group</label>
        <div class="ticket-info" v-if="validTicketCode">{{ showGroup }}</div>
        <img
          class="pre-form--value"
          src="/assets/images/code-3.png"
          alt=""
          v-if="!validTicketCode"
        />
      </div>
    </div>
    <div class="d-flex justify-content-center mt-5 mb-4">
      <pnw-button
        variant="primary"
        size="xl"
        @click="registerCode"
        :disabled="!validTicketCode || pastTicket || inUsing"
      >
        Register for Class
      </pnw-button>
    </div>
    <div class="pre-btns centered">
      <a
        href="https://tickets.secretcinema.org/ghostbusters-gates-of-gozer"
        target="_blank"
        class="pre-btn pre-btn-underline"
      >
        <div class="pre-btn--copy">Don’t have tickets? Get tickets now</div>
      </a>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import voltApiService from "../../services/VoltApiService";
import ErrorAlert from "../../components/ErrorAlert.vue";
import routerItems from "../../constants/routerItems";
import PnwButton from "../../components/PnwButton.vue";
import PnwInput from "../../components/PnwInput.vue";
import http from "../../services/HttpService";
import generateName from "../../utils/nameGenerator";
import {
  startSoonLimit,
  pastStartThreshold,
} from "../../constants/showsConstants";

import random from "random-name";
import playSound from "../../services/AudioService";
import dayHelpers from "../../utils/dayHelper";
import auth from "../../services/AuthService";

export default {
  components: { ErrorAlert, PnwButton, PnwInput },
  name: "TicketCode",
  data() {
    return {
      form: {
        ticketCode: "",
      },
      ticketInfo: null,
      validTicketCode: null,
      checking: false,
      isStartSoon: false,
      pastTicket: false,
      inUsing: false,
    };
  },
  mounted() {
    this.form.ticketCode = this.onboardingData.ticketCode;
    this.fetchTicketInfo();
  },
  computed: {
    ...mapGetters({
      currentProfile: "currentProfile",
      currentUser: "currentUser",
      onboardingData: "registerStore/onboardingData",
      isAuthenticated: "isAuthenticated",
    }),
    profileId() {
      return this.currentProfile?.id || "";
    },
    showDate() {
      return dayHelpers.getShowDate(this.ticketInfo);
    },
    showTime() {
      return dayHelpers.getShowTime(this.ticketInfo);
    },
    showGroup() {
      if (this.ticketInfo?.event) {
        return this.ticketInfo.ticketType;
      }
      return "-";
    },
  },
  methods: {
    ...mapActions({
      updateOnboardingData: "registerStore/updateOnboardingData",
      updateTicketInfo: "updateTicketInfo",
      resetAction: "registerStore/reset",
      updateProfile: "updateProfile",
      addProfile: "addProfile",
    }),
    getTickets() {
      window.open(
        `https://tickets.secretcinema.org/ghostbusters-gates-of-gozer/`,
        "_blank"
      );
    },
    logout() {
      auth.logout();
      this.$router.push({ name: this.$routerItems.LANDING });
    },
    async registerCode() {
      try {
        this.updateOnboardingData({
          newProfile: !!this.$route.query.newProfile,
          ticketCode: this.form.ticketCode,
          listingId: this.extractListingId(this.ticketInfo?.event?.listingName),
          ticketType: this.ticketInfo?.ticketType,
          inProgressing: true,
        });
        if (this.isStartSoon) {
          // auto generate profile
          const photos = ["boy.png", "girl.png", "female.png", "male.png"];
          const photo =
            photos[
              (Math.round(Math.random() * 1000) % 2) +
                (this.onboardingData.isAdult ? 2 : 0)
            ];

          const userInfo = {
            isAdult: this.onboardingData.isAdult,
            ticketCode: this.form.ticketCode,
            nickName:
              this.currentUser.firstName +
              " " +
              this.currentUser.lastName.charAt(0),
            jobTitle: generateName(Math.round(Math.random() * 1000) % 12),
            photoUrl: "/assets/images/profiles/" + photo,
            listingId: this.extractListingId(
              this.ticketInfo?.event?.listingName
            ),
            ticketType: this.ticketInfo?.ticketType,
          };

          if (this.onboardingData.newProfile) {
            await this.addProfile(userInfo);
          } else {
            await this.updateProfile({
              profileId: this.currentProfile.id,
              profile: userInfo,
            });
          }
          playSound("GF-PN1-ProfileAssigned.wav");
          this.resetAction();
        }
        if (this.isStartSoon) {
          this.$router.push({ name: routerItems.EXPEDITED });
        } else {
          this.$router.push({ name: routerItems.WELCOME });
        }
      } catch (err) {
        console.log(err);
      }
    },
    /*
     * Extracts listing id from the listing name, which is the part after the slash inside the parentheses
     * e.g.
     * listing name: Secret Cinema presents Ghostbusters: The Gates of Gozer (UK/DR20)
     * listing id: DR20
     */
    extractListingId(listingName) {
      try {
        if (listingName) {
          const groups = listingName.match(/^.+\(.+\/(.+)\)$/);
          if (groups.length >= 2) {
            return groups[1];
          }
        }
      } catch (err) {
        console.error(
          `error extracting ticket id from listing name ${listingName}`
        );
      }

      return "";
    },
    async fetchTicketInfo() {
      this.validTicketCode = null;
      this.ticketInfo = null;
      if (!this.form.ticketCode) return;
      this.checking = true;
      this.pastTicket = false;
      this.inUsing = false;
      try {
        const ticketInfo = await voltApiService.getTicketInfo(
          this.form.ticketCode
        );
        if (ticketInfo.event) {
          this.validTicketCode = true;
          this.ticketInfo = ticketInfo;
          this.updateTicketInfo(ticketInfo);
          let duration = dayHelpers.getShowDuration(this.ticketInfo, "minutes");
          if (duration < -pastStartThreshold) {
            this.pastTicket = true;
          } else {
            this.pastTicket = false;
          }
          const {
            data: { valid },
          } = await http.post("/api/pnw/ticket-code-check", {
            ticketCode: this.form.ticketCode,
          });
          this.inUsing =
            !valid && process.env.VUE_APP_NODE_DEV_TEST !== "self-testing";
          if (this.inUsing || this.pastTicket) {
            this.checking = false;
            playSound("GF-SpiritNet-Error.wav");
            return;
          }
          if (duration < startSoonLimit) {
            this.isStartSoon = true;
          } else {
            this.isStartSoon = false;
          }
        } else {
          playSound("GF-SpiritNet-Error.wav");
          this.validTicketCode = false;
        }
      } catch (err) {
        playSound("GF-SpiritNet-Error.wav");
        this.validTicketCode = false;
      }
      this.checking = false;
    },
    checkTicketCode() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      // this timer is needed for the debounce search
      this.timer = setTimeout(async () => {
        await this.fetchTicketInfo();
      }, 800);
    },
  },
};
</script>
<style lang="scss" scoped>
.auth-form {
  max-width: 600px;
}
.ticket-code {
  position: relative;
  .spinner {
    position: absolute;
    right: 7px;
    top: 7px;
    z-index: 100;
  }
}
.ticket-info {
  font-size: 28px;
}
.ticket-status {
  position: fixed;
  bottom: 120px;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
}
.qr-code {
  max-width: 160px !important;
}
</style>
